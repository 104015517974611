<template>
  <div>
    Coming Soon
  </div>
</template>

<script>
export default {
}
</script>
