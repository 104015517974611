import { get } from 'lodash'

const sort = (items = [], path = null) => {
  return [
    ...items
  ].sort((a, b) => {
    if (path) {
      a = (get(a, path) || '')
      b = (get(b, path) || '')
    }
    if ((a + '').toLowerCase() < (b + '').toLowerCase()) return -1
    if ((a + '').toLowerCase() > (b + '').toLowerCase()) return 1
    return 0
  })
}

export default sort
