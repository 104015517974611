<template>
  <v-sheet
    class="create-account-view"
  >
    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      class="create-account-left-bar"
    />
    <img
      class="create-account-logo"
      alt="Patch logo"
      src="https://cdn.citygro.com/assets/patch-logo.svg"
    />
    <div
      class="create-account-content pa-2"
    >
      <account-info
        v-show="step === 1"
        :account="accountChanged"
        :user="userChanged"
        :disabled="loading"
        @change="$handleChange($event[0], $event[1], $event[2])"
        @next="step = 2"
      />
      <account-holder-info
        v-show="step === 2"
        :account="accountChanged"
        :user="userChanged"
        :disabled="loading"
        @change="$handleChange($event[0], $event[1], $event[2])"
        @back="step = 1"
        @next="step = 3"
      />
      <marketing-info
        v-show="step === 3"
        :account="accountChanged"
        :disabled="loading"
        @change="$handleChange($event[0], $event[1], $event[2])"
        @back="step = 2"
        @next="step4()"
      />
      <contact-sync
        v-if="step === 4"
        :account="accountChanged"
        :disabled="loading"
        @change="$handleChange($event[0], $event[1], $event[2])"
        @back="step = 3"
        @save="save"
      />
    </div>
  </v-sheet>
</template>

<style lang="scss">
.create-account-view {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  overflow-y: auto;
  .create-account-left-bar {
    background-color: $primary;
    width: 30px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .create-account-logo {
    width: 175px;
    position: absolute;
    top: 10px;
    left: 40px;
  }
  .create-account-content {
    max-width: 750px;
    margin: 0 auto;
    margin-top: 100px;
  }
}
</style>

<script>
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'
import AccountInfo from './AccountInfo'
import MarketingInfo from './MarketingInfo'
import { Account, User, Addon } from 'ui/models'
import AccountHolderInfo from './AccountHolderInfo'
import ContactSync from '@/components/CreateAccount/ContactSync'

export default {
  mixins: [
    createChangeTrackerMixin({ path: ['account', 'user'] })
  ],
  components: {
    ContactSync,
    AccountHolderInfo,
    AccountInfo,
    MarketingInfo
  },
  data () {
    return {
      step: 1,
      loading: false
    }
  },
  computed: {
    account () {
      return this.$store.getters.account || {}
    },
    user () {
      return this.$store.getters.user || {}
    },
    addon () {
      return Addon.query()
        .where(a => ['WORDPRESS', 'SHOPIFY'].includes(a.type))
        .first()
    }
  },
  methods: {
    async step4 () {
      if (
        this.addon?.type === 'SHOPIFY'
      ) {
        this.step = 4
      } else {
        await this.save()
      }
    },
    async save (event) {
      let notes = this.accountChanged?.__citygro?.notes || ''
      const aov = (this.accountChanged?.settings?.rfm?.average_order_value || 10000) / 100
      if (aov && !notes.includes('AOV:')) {
        notes = (notes)
          ? notes + '\nAOV: ' + aov
          : 'AOV: ' + aov
      }
      if (!notes.includes('Filter:')) {
        notes = (notes)
          ? notes + '\nFilter: ' + !!(this.addon?.config?.filter)
          : 'Filter: ' + !!(this.addon?.config?.filter)
      }
      if (!notes.includes('Sync:')) {
        notes = (notes)
          ? notes + '\nSync: ' + event
          : 'Sync: ' + event
      }
      this.$handleChange(
        'account',
        '__citygro.notes',
        notes
      )
      this.loading = true
      try {
        await User.save(this.userChanged)
        await Account.save({
          ...this.accountChanged,
          finish_signup: true
        })
        window.location.reload(true)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
