import load from './load'
import settingsRoutes from '@/modules/Settings/routes'

export default [
  {
    path: '/',
    name: 'SelectAccount',
    component: load(() => import('@/modules/SelectAccount'))
  },
  {
    path: '/profile',
    name: 'Profile',
    component: load(() => import('@/modules/Profile')),
    meta: {
      icon: 'fa-user-cog'
    }
  },
  {
    path: '/admin/invoices',
    name: 'AdminInvoices',
    component: load(() => import('@/modules/Admin/Invoices')),
    meta: {
      restrict: {
        'user.level': { $gte: 5 }
      }
    }
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: load(() => import('@/modules/Admin/Users')),
    meta: {
      restrict: {
        'user.level': { $gte: 5 }
      }
    }
  },
  { // Use this route if an account is currently selected so we don't need to leave the context of the current account
    path: '/:accountId/profile',
    name: 'ProfileAccount',
    component: load(() => import('@/modules/Profile')),
    meta: {
      icon: 'fa-user-cog'
    }
  },
  {
    path: '/:accountId',
    redirect: {
      name: 'Home'
    }
  },
  {
    path: '/:accountId/home',
    name: 'Home',
    component: load(() => import('@/modules/Home')),
    meta: {
      nav: 'primary',
      icon: 'fa-home',
      welcome: load(() => import('@/modules/Home/WelcomeDialog'))
    }
  },
  {
    path: '/:accountId/dashboard',
    name: 'Dashboard',
    redirect: '/:accountId/dashboard/0',
    component: load(() => import('@/modules/Dashboard')),
    children: [
      {
        path: 'retention',
        name: 'DashboardRetention',
        component: load(() => import('@/modules/Dashboard/Retention')),
        meta: {
          label: 'Retention',
          nav: true,
          icon: 'fas fa-magnet',
          name: 'Retention Dashboard',
          welcome: load(() => import('@/modules/Dashboard/RetentionWelcomeDialog'))
        }
      },
      {
        path: 'marketing',
        name: 'DashboardMarketing',
        component: load(() => import('@/modules/Dashboard/Marketing')),
        meta: {
          label: 'Marketing',
          name: 'Marketing Dashboard',
          nav: true,
          icon: 'fas fa-bullhorn',
          restrict: {
            'account.status': { $in: ['ACTIVE', 'HIDDEN'] }
          },
          roadblock: load(() => import('@/modules/Dashboard/MarketingRoadblockDialog')),
          welcome: load(() => import('@/modules/Dashboard/MarketingWelcomeDialog'))
        }
      },
      {
        path: 'tools',
        name: 'DashboardTools',
        component: load(() => import('@/modules/Dashboard/Tools')),
        meta: {
          label: 'Tools',
          name: 'Tools Dashboard',
          nav: true,
          icon: 'fas fa-tools',
          restrict: {
            'account.status': { $in: ['ACTIVE', 'HIDDEN'] }
          },
          roadblock: load(() => import('@/modules/Dashboard/ToolsRoadblockDialog')),
          welcome: load(() => import('@/modules/Dashboard/ToolsWelcomeDialog'))
        }
      },
      {
        path: '/:accountId/dashboard/:dashboardId',
        name: 'DashboardMy',
        component: load(() => import('@/modules/Dashboard/My')),
        meta: {
          label: 'My Dashboard',
          name: 'Dashboard',
          nav: false,
          icon: 'far fa-eye'
        }
      }
    ],
    meta: {
      nav: 'primary',
      icon: 'fas fa-th-large',
      restrict: {
        'addons.type': 'DASHBOARD'
      },
      children (children, store) {
        return [
          ...(store.getters?.account?.settings?.dashboards || []).map((dashboard, dashboardId) => ({
            name: 'DashboardMy',
            params: {
              dashboardId
            },
            meta: {
              label: dashboard?.name || 'Dashboard ' + dashboardId,
              icon: 'fa fa-eye'
            }
          })),
          ...children
        ]
      }
    }
  },
  {
    path: '/:accountId/contacts',
    name: 'Contacts',
    component: load(() => import('@/modules/Contacts')),
    meta: {
      nav: 'primary',
      icon: 'fa-address-book',
      restrict: {
        'addons.type': 'CONTACTS'
      },
      welcome: load(() => import('@/modules/Contacts/WelcomeDialog'))
    }
  },
  {
    path: '/:accountId/messenger/:conversationId?',
    name: 'Messenger',
    component: load(() => import('@/modules/Messenger')),
    meta: {
      nav: 'secondary',
      icon: 'far fa-comments',
      indicator: {
        id: 'MESSENGER',
        value: (record) => record && record.value ? +(record.value.unread || 0) + +(record.value.unsnoozed || 0) : 0
      },
      restrict: {
        'addons.type': { $in: ['MESSENGER'] }
      },
      navRestrict: {
        'addons.type': { $in: ['BUNDLE-PATCH-TRIAL'] }
      },
      noAutoScroll: true,
      fullHeight (vm) {
        return vm.$vuetify.breakpoint.mdAndUp
      },
      roadblock: load(() => import('@/modules/Messenger/RoadblockDialog')),
      welcome: load(() => import('@/modules/Messenger/WelcomeDialog'))
    }
  },
  {
    path: '/:accountId/blasts',
    // Top level routes that have a default child MUST NOT have
    // a `name` property. Treat `name` like a globally unique ID.
    // Need to specify the label of a router link? Use meta.label
    // Need to specify linkable name of default child? meta.name
    component: load(() => import('@/modules/Blasts')),
    meta: {
      name: 'Blasts', // navigable `name`/ID of default child
      label: 'Blasts', // user facing text for this route
      nav: 'secondary',
      icon: 'fa-bullhorn',
      restrict: {
        'addons.type': { $in: ['BLASTS'] }
      },
      navRestrict: {
        'addons.type': { $in: ['BUNDLE-PATCH-TRIAL'] }
      }
    },
    children: [
      {
        name: 'BlastEditor',
        path: ':blastId',
        component: load(() => import('@/modules/Blasts/BlastEditor')),
        meta: {
          restrict: {
            'addons.type': { $in: ['BLASTS'] }
          }
        }
      },
      {
        name: 'Blasts',
        path: '',
        component: load(() => import('@/modules/Blasts/BlastsList')),
        meta: {
          restrict: {
            'addons.type': { $in: ['BLASTS'] }
          },
          roadblock: load(() => import('@/modules/Blasts/RoadblockDialog')),
          welcome: load(() => import('@/modules/Blasts/WelcomeDialog'))
        }
      }
    ]
  },
  {
    path: '/:accountId/automations',
    component: load(() => import('@/modules/Automations')),
    meta: {
      name: 'Automations',
      label: 'Automations',
      nav: 'secondary',
      icon: 'fa-robot',
      restrict: {
        'addons.type': { $in: ['AUTOMATIONS'] }
      },
      navRestrict: {
        'addons.type': { $in: ['BUNDLE-PATCH-TRIAL'] }
      }
    },
    children: [
      {
        name: 'AutomationEditor',
        path: ':sequenceId',
        component: load(() => import('@/modules/Automations/AutomationEditor')),
        meta: {
          restrict: {
            'addons.type': { $in: ['AUTOMATIONS'] }
          }
        }
      },
      {
        name: 'Automations',
        path: '',
        component: load(() => import('@/modules/Automations/AutomationsList')),
        meta: {
          restrict: {
            'addons.type': { $in: ['AUTOMATIONS'] }
          },
          roadblock: load(() => import('@/modules/Automations/RoadblockDialog')),
          welcome: load(() => import('@/modules/Automations/WelcomeDialog'))
        }
      }
    ]
  },
  {
    path: '/:accountId/calendar',
    name: 'Calendar',
    component: load(() => import('@/modules/Calendar')),
    meta: {
      label: 'Calendar',
      name: 'Calendar',
      nav: 'secondary',
      icon: 'fas fa-calendar',
      restrict: {
        'addons.type': 'CALENDAR'
      }
    }
  },
  {
    path: '/:accountId/kiosk',
    component: load(() => import('@/modules/Kiosk')),
    meta: {
      name: 'Kiosk',
      label: 'Kiosk',
      nav: 'secondary',
      icon: 'fa-tablet-alt fa-rotate-270',
      restrict: {
        'addons.type': 'KIOSK'
      }
    },
    children: [
      {
        name: 'Kiosk',
        path: '',
        component: load(() => import('@/modules/Kiosk/KioskList')),
        meta: {
          restrict: {
            'addons.type': 'KIOSK'
          }
        }
      },
      {
        name: 'KioskDevices',
        path: 'devices',
        component: load(() => import('@/modules/Kiosk/Devices/Devices')),
        meta: {
          name: 'Devices',
          label: 'Devices',
          nav: true,
          icon: 'fa-tablet-alt fa-rotate-270',
          restrict: {
            'addons.type': 'KIOSK'
          }
        }
      },
      {
        name: 'KioskDeviceEditor',
        path: 'devices/:deviceId',
        component: load(() => import('@/modules/Kiosk/Devices/DeviceEditor')),
        props: (route) => ({ deviceId: 1 * route.params.deviceId }),
        meta: {
          restrict: {
            'addons.type': 'KIOSK'
          }
        }
      },
      {
        name: 'KioskEditor',
        path: ':flowId',
        component: load(() => import('@/modules/Kiosk/KioskEditor')),
        props: (route) => ({
          flowId: route.params.flowId,
          name: route.params.name
        }),
        meta: {
          restrict: {
            'addons.type': 'KIOSK'
          }
        }
      }
    ]
  },
  {
    path: '/:accountId/loyaltycash',
    name: 'LoyaltyCash',
    component: load(() => import('@/modules/LoyaltyCash')),
    meta: {
      label: 'Loyalty Cash',
      nav: 'secondary',
      icon: 'fas fa-money-bill-wave',
      restrict: {
        'addons.type': { $in: ['LOYALTYCASH'] }
      },
      navRestrict: {
        'addons.type': { $in: ['BUNDLE-PATCH-TRIAL'] }
      },
      fullHeight (vm) {
        return vm.$vuetify.breakpoint.mdAndUp
      },
      roadblock: load(() => import('@/modules/LoyaltyCash/RoadblockDialog')),
      welcome: load(() => import('@/modules/LoyaltyCash/WelcomeDialog'))
    }
  },
  {
    path: '/:accountId/textchat',
    name: 'TextChat',
    component: load(() => import('@/modules/TextChat')),
    meta: {
      label: 'TextChat',
      nav: 'secondary',
      icon: 'fas fa-comment',
      restrict: {
        'addons.type': { $in: ['TEXTCHAT'] }
      },
      navRestrict: {
        'addons.type': { $in: ['BUNDLE-PATCH-TRIAL'] }
      },
      fullHeight (vm) {
        return vm.$vuetify.breakpoint.mdAndUp
      },
      roadblock: load(() => import('@/modules/TextChat/RoadblockDialog')),
      welcome: load(() => import('@/modules/TextChat/WelcomeDialog'))
    }
  },
  {
    path: '/:accountId/age-gate',
    name: 'Age Gate',
    component: load(() => import('@/modules/AgeGate')),
    meta: {
      label: 'Age Gate',
      nav: 'secondary',
      icon: 'fa-solid fa-torii-gate',
      restrict: {
        'addons.type': { $in: ['AGE-GATE'] }
      },
      navRestrict: {
        'addons.type': { $in: ['BUNDLE-PATCH-TRIAL'] }
      },
      fullHeight (vm) {
        return vm.$vuetify.breakpoint.mdAndUp
      }
      // roadblock: load(() => import('@/modules/TextChat/RoadblockDialog')),
      // welcome: load(() => import('@/modules/TextChat/WelcomeDialog'))
    }
  },
  {
    path: '/:accountId/forms',
    // Top level routes that have a default child MUST NOT have
    // a `name` property. Treat `name` like a globally unique ID.
    // Need to specify the label of a router link? Use meta.label
    // Need to specify linkable name of default child? meta.name
    component: load(() => import('@/modules/Forms')),
    meta: {
      name: 'Forms', // navigable `name`/ID of default child
      label: 'Forms', // user facing text for this route
      nav: 'secondary',
      icon: 'fas fa-file',
      restrict: {
        'addons.type': 'FORMS'
      }
    },
    children: [
      {
        name: 'FormEditor',
        path: ':formId',
        component: load(() => import('@/modules/Forms/FormEditor')),
        meta: {
          restrict: {
            'addons.type': { $in: ['FORMS'] }
          }
        }
      },
      {
        name: 'Forms',
        path: '',
        component: load(() => import('@/modules/Forms/FormsList')),
        meta: {
          restrict: {
            'addons.type': { $in: ['FORMS'] }
          },
          roadblock: load(() => import('@/modules/Forms/RoadblockDialog')),
          welcome: load(() => import('@/modules/Forms/WelcomeDialog'))
        }
      }
    ]
  },
  {
    path: '/:accountId/reports',
    name: 'Reports',
    component: load(() => import('@/modules/Reports')),
    meta: {
      label: 'Reports',
      nav: 'secondary',
      icon: 'fas fa-file-invoice',
      restrict: {
        'addons.type': 'REPORTS'
      },
      roadblock: load(() => import('@/modules/Reports/RoadblockDialog'))
    }
  },
  {
    path: '/:accountId/auto-replies/:recordId?',
    name: 'AutoReplies',
    component: load(() => import('@/modules/AutoReplies')),
    meta: {
      label: 'Auto-Replies',
      nav: 'secondary',
      icon: 'fas fa-reply-all',
      restrict: {
        $and: [
          { 'addons.type': 'AUTOREPLIES' },
          { 'addons.type': 'MESSENGER' } // The code for running auto-replies has always depended on having messenger
        ]
      },
      navRestrict: {
        'addons.type': { $in: ['BUNDLE-PATCH-TRIAL'] }
      },
      fullHeight (vm) {
        return vm.$vuetify.breakpoint.mdAndUp
      },
      roadblock: load(() => import('@/modules/AutoReplies/RoadblockDialog')),
      welcome: load(() => import('@/modules/AutoReplies/WelcomeDialog'))
    }
  },
  {
    path: '/:accountId/keywords',
    name: 'Keywords',
    component: load(() => import('@/modules/Keywords')),
    meta: {
      label: 'Keywords',
      nav: 'secondary',
      icon: 'fas fa-key',
      restrict: {
        'addons.type': 'KEYWORDS'
      }
      // roadblock: load(() => import('@/modules/Keywords/RoadblockDialog')),
      // welcome: load(() => import('@/modules/Keywords/WelcomeDialog'))
    }
  },
  {
    path: '/:accountId/smartlinks',
    name: 'Smartlinks',
    component: load(() => import('@/modules/Smartlinks')),
    meta: {
      label: 'SmartLinks',
      nav: 'secondary',
      icon: 'fas fa-link',
      restrict: {
        'addons.type': { $in: ['SMARTLINKS'] }
      },
      navRestrict: {
        'addons.type': { $in: ['BUNDLE-PATCH-TRIAL'] }
      },
      roadblock: load(() => import('@/modules/Smartlinks/RoadblockDialog')),
      welcome: load(() => import('@/modules/Smartlinks/WelcomeDialog'))
    }
  },
  {
    path: '/:accountId/offers',
    component: load(() => import('@/modules/Offers')),
    meta: {
      label: 'SmartOffers',
      name: 'Offers',
      nav: 'secondary',
      icon: 'fas fa-tags',
      restrict: {
        'addons.type': 'OFFERS'
      }
    },
    children: [
      {
        name: 'OfferEditor',
        path: ':recordId',
        component: load(() => import('@/modules/Offers/OfferEditor')),
        meta: {
          restrict: {
            'addons.type': 'OFFERS'
          }
        }
      },
      {
        name: 'Offers',
        path: '',
        component: load(() => import('@/modules/Offers/OffersList')),
        meta: {
          label: 'SmartOffers',
          restrict: {
            'addons.type': 'OFFERS'
          }
        }
      }
    ]
  },
  {
    path: '/:accountId/documents',
    component: load(() => import('@/modules/Documents')),
    meta: {
      name: 'Documents',
      label: 'Waivers',
      nav: 'secondary',
      icon: 'fab fa-wpforms',
      restrict: {
        'addons.type': 'WAIVERS'
      }
    },
    children: [
      {
        name: 'Documents',
        path: '',
        component: load(() => import('@/modules/Documents/DocumentsList')),
        meta: {
          label: 'Waivers',
          restrict: {
            'addons.type': 'WAIVERS'
          }
        }
      },
      {
        name: 'DocumentEditor',
        path: ':documentId',
        component: load(() => import('@/modules/Documents/DocumentEditor')),
        props: (route) => ({
          documentId: route.params.documentId,
          name: route.params.name
        }),
        meta: {
          label: 'Waiver Editor',
          restrict: {
            'addons.type': 'WAIVERS'
          }
        }
      }
    ]
  },
  {
    path: '/:accountId/pixel',
    name: 'Pixel',
    component: load(() => import('@/modules/Pixel')),
    meta: {
      label: 'Pixel',
      nav: 'secondary',
      icon: 'fa-laptop-code',
      restrict: {
        'addons.type': { $in: ['LOYALTYCASH', 'TEXTCHAT', 'FORMS', 'AGE-GATE'] }
      },
      navRestrict: {
        'addons.type': { $in: ['BUNDLE-PATCH-TRIAL'] }
      },
      roadblock: load(() => import('@/modules/Pixel/RoadblockDialog'))
    }
  },
  {
    path: '/:accountId/eventlog',
    name: 'EventLog',
    component: load(() => import('@/modules/EventLog')),
    meta: {
      label: 'Event Log',
      nav: 'secondary',
      icon: 'fa-clipboard-list',
      restrict: {
        'addons.type': 'EVENTLOG'
      },
      roadblock: load(() => import('@/modules/EventLog/RoadblockDialog'))
    }
  },
  {
    path: '/:accountId/upgrade',
    name: 'Upgrade',
    component: load(() => import('@/modules/Upgrade')),
    meta: {
      name: 'Upgrade'
    }
  },
  ...settingsRoutes(load),
  {
    path: '*',
    redirect: '/'
  }
]
