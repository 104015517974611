import Trigger from '../Trigger'

class SmsIn extends Trigger {
  static get title () {
    return 'Incoming SMS'
  }

  static get class () {
    return 'SmsIn'
  }

  // These are deprecated in favor of shared/schemas/events/*
  static getSchemas () {
    return [
      { key: 'from', name: 'From (phone number)', type: 'text' },
      { key: 'to', name: 'To (phone number)', type: 'text' },
      { key: 'content.text', name: 'Message Text', type: 'text' },
      { key: 'conversation_id', name: 'Conversation ID', type: '_id' },
      { key: 'keyword_id', name: 'Keyword ID', type: '_id', resource: 'keywords' },
      { key: 'gateway_id', name: 'Gateway ID', type: '_id', resource: 'gateways' },
      { key: 'auto_reply_id', name: 'Auto Reply ID', type: '_id', resource: 'auto_replies' }
    ]
  }

  static getDefaultProps () {
    return {
      occur: {
        frequency: 'EVERY',
        n: 1,
        start: 1
      }
    }
  }
}

export default SmsIn
