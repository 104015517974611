const entity = 'Contact'
const path = 'contact'

// This is a base model for all default fields defined in every account
// The /v2/fields resource (and fields section of portal) allows for custom fields
// to be created which will take priority over these if they have the same key

// Schemas used to be provided as a nested object or array of nested objects, which
// got confusing and difficult to merge.  To make this easier schemas are now just an object
// keyed by the path of each field

// Generally these will be prepended with "contact." when used with a payload

const schemas = {
  _id: { name: 'ID', type: '_id', searchable: true, immutable: true },
  account_id: { name: 'Account', type: 'number', immutable: true, cast: 'int', multiple: true, unique: true },
  _last_account_id: { name: 'Last Account', type: 'number', immutable: true, cast: 'int' },
  first_name: { name: 'First Name', type: 'text', searchable: true },
  last_name: { name: 'Last Name', type: 'text', searchable: true },
  phone: { name: 'Phone Number', type: 'text', validation: [{ type: 'regexp', value: '^[0-9]{10,11}$' }], placeholder: '(___) ___-____', input_type: 'tel', mask: '(___) ___-____', data_mask: '1__________', replace: [{ pattern: '\\D', replacement: '' }], searchable: true },
  email: { name: 'Email Address', type: 'text', validation: [{ type: 'regexp', value: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$' }], placeholder: 'your@email.com', input_type: 'email', replace: [{ pattern: ' ', replacement: '' }, { case: 'lower' }], searchable: true },
  gender: { name: 'Gender', type: 'text', options: [{ value: 'Female' }, { value: 'Male' }], searchable: true },
  birthday: { name: 'Birthday', type: 'date' },
  photo: { name: 'Profile Image', type: 'image' }, // Consider adding this: settings: { max_width: 150, max_height: 150 }
  punchcard: { name: 'Loyalty Points', type: 'punchcard' },
  address: { name: 'Address', type: 'text', searchable: true },
  address2: { name: 'Address2', type: 'text', searchable: true },
  city: { name: 'City', type: 'text', searchable: true },
  state: { name: 'State', type: 'text', searchable: true },
  zip: { name: 'Zip', type: 'text', validation: [{ type: 'number', value: '' }], searchable: true },
  sms_on: { name: 'SMS On', type: 'checkbox' },
  email_on: { name: 'Email On', type: 'checkbox' },
  sms_bounce: { name: 'SMS Bounce', type: 'checkbox' },
  email_bounce: { name: 'Email Bounce', type: 'checkbox' },
  groups: { name: 'Groups', type: 'number', cast: 'int', multiple: true, resource: 'groups', unique: true },
  tags: { name: 'Tags', type: 'text', multiple: true, resource: 'tags', unique: true },
  created_at: { name: 'Create Time', type: 'timestamp', immutable: true },
  updated_at: { name: 'Last Modification Time', type: 'timestamp', immutable: true },
  checkins: { name: '# Checkins', type: 'number', cast: 'int', immutable: true },
  last_checkin_at: { name: 'Last Checkin Time', type: 'timestamp', immutable: true },
  last_message_at: { name: 'Last Message Time', type: 'timestamp', immutable: true },
  channel: { name: 'Opt-In Channel', type: 'text', immutable: true },
  punchcards: { name: 'Punchcards', type: 'object', immutable: true, hidden: true, strict: false },
  // _files Ended up only being used for Documents/Waivers.  I planned on it being used for other things.
  //  It might be used in a few filters here and there still so we need to keep it
  _files: { name: 'Waivers', type: 'object', multiple: true, immutable: true, hidden: true },
  '_files.id': { name: 'ID', type: '_id', immutable: true, hidden: true, parent: '_files' },
  '_files.account_id': { name: 'Account ID', type: 'number', immutable: true, hidden: true, parent: '_files' },
  '_files.filename': { name: 'Filename', type: 'text', immutable: true, hidden: true, parent: '_files' },
  '_files.mime': { name: 'Mime Type', type: 'text', immutable: true, hidden: true, parent: '_files' },
  // Would like to eliminate this but unfortunately some 3rd party integrations somehow were implemented to use this
  '_files.waiver_id': { name: 'Waiver ID', type: 'text', hidden: true, parent: '_files' },
  '_files.document_id': { name: 'Waiver ID', type: 'text', resource: 'waivers', hidden: true, parent: '_files' },
  '_files.created_at': { name: 'Create Time', type: 'timestamp', hidden: true, parent: '_files' },
  _sms0: { name: 'Opt-in Invitation SMS Sent', type: 'checkbox' },
  _sms1: { name: 'Opt-in Confirmation SMS Sent', type: 'checkbox' },
  ban: { name: 'Banned', type: 'checkbox' },
  _lead: { name: 'Initially A Lead', type: 'checkbox', hidden: true },
  _rfm: { name: 'RFM', type: 'Object', immutable: true, hidden: true },
  '_rfm.r': { name: 'Recency', type: 'number', immutable: true, parent: '_rfm', options: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }] },
  '_rfm.f': { name: 'Frequency', type: 'number', immutable: true, parent: '_rfm', options: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }] },
  '_rfm.m': { name: 'Monetary', type: 'number', immutable: true, parent: '_rfm', options: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }] },
  '_rfm.value': { name: 'Value', type: 'text', immutable: true, parent: '_rfm' },
  '_rfm.avg': { name: 'Average', type: 'number', immutable: true, parent: '_rfm' },
  _loyalty: { name: 'Loyalty Member', type: 'checkbox' },
  _loyaltycash_earnings: { name: 'Loyalty Cash Earnings', type: 'string', multiple: true, immutable: true, hidden: true },
  // These should be removed eventually but offers depend on them
  claims: { name: 'Offers Activated', type: 'object', immutable: true, multiple: true, strict: true, hidden: true },
  'claims._id': { name: 'ID', type: '_id', immutable: true, hidden: true, parent: 'claims' },
  'claims.offer_id': { name: 'Offer', type: 'number', cast: 'int', resource: 'offers', parent: 'claims' },
  'claims.allowed': { name: '# Redemptions Allowed', type: 'number', cast: 'int', parent: 'claims', hidden: true },
  'claims.remaining': { name: '# Redemptions Left', type: 'number', cast: 'int', parent: 'claims', hidden: true },
  'claims.expires_at': { name: 'Expire Time', type: 'timestamp', parent: 'claims' },
  'claims.t': { name: 'Create Time', type: 'timestamp', immutable: true, parent: 'claims', hidden: true }
}

const getSchemas = ({ addons } = {}) => {
  let _schemas = schemas
  if (
    (addons || []).find(addon =>
      ['NCRSILVER', 'REVEL', 'SHOPIFY', 'ORDERS'].includes(addon.type)
    )
  ) {
    _schemas = {
      ..._schemas,
      _total_spent: {
        name: 'Orders - Total Spent',
        type: 'number',
        immutable: true
      },
      _order_count: {
        name: 'Orders - Total Count',
        type: 'number',
        immutable: true
      },
      _last_order_at: {
        name: 'Orders - Last Order At',
        type: 'timestamp',
        immutable: true
      },
      _item_names_ordered: {
        name: 'Orders - Item Names',
        type: 'text',
        multiple: true,
        immutable: true
      },
      _skus_ordered: {
        name: 'Orders - SKUs',
        type: 'text',
        multiple: true,
        immutable: true
      }
    }
  }
  return _schemas
}

export default getSchemas

export {
  getSchemas,
  schemas,
  entity,
  path
}
